import React, { Component } from "react"
import { Link } from "gatsby"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import styled from "styled-components"

const StyledNavbar = styled(Navbar)`
  min-height: 70px;
  text-tranform: uppercase;
  background-color: #333 !important;

  .m8-menu-list-li {
    text-transform: uppercase;
    &:hover {
      color: #cf3400;
    }
  }
`;

const StyledNavbarToggle = styled(Navbar.Toggle)`
	color: #eee !important;
	border-color: #333 !important;
`;

export class Header extends Component {
  render() {
    return (
      <StyledNavbar bg="dark" expand="lg" sticky="top">
        <Navbar.Brand>
          <Link className="standard-logo" to="/">
            <img
              src="/assets-m8/images/logo/m8-logo-color-horizontal-white.svg"
              height="23"
              width="200"
              alt="M8 Systems Logo"
            />
          </Link>
        </Navbar.Brand>
        <StyledNavbarToggle aria-controls="basic-navbar-nav">
					<i className="icon-reorder"></i>
        </StyledNavbarToggle>
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav>
            <Nav.Link as="span">
              <Link className="m8-menu-list-li" to="/">
                <div>
                  Home
                </div>
              </Link>
            </Nav.Link>
            <Nav.Link as="span">
              <Link className="m8-menu-list-li" to="/">
                <div>
                  Products
                </div>
              </Link>
            </Nav.Link>
            {/* <Nav.Link as="span">
              <Link className="m8-menu-list-li" to="/">
                <div>
                  Pricing
                </div>
              </Link>
            </Nav.Link> */}
            {/* <Nav.Link as="span">
              <Link className="m8-menu-list-li" to="/news">
                <div>
                  News
                </div>
              </Link>
            </Nav.Link> */}
            <Nav.Link as="span">
              <Link className="m8-menu-list-li" to="/team">
                <div>
                  Team
                </div>
              </Link>
            </Nav.Link>
            <Nav.Link as="span">
              <Link className="m8-menu-list-li" to="/">
                <div>
                  Contact
                </div>
              </Link>
            </Nav.Link>
            {/* <Nav.Link as="span">
              <a
                id="m8-menu-list-li"
                className="orange"
                href="https://dashboard.m8systems.com/dashboard.html"
              >
                <div id="dashboard-link">
                  Dashboard
                </div>
              </a>
            </Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </StyledNavbar>
    )
  }
}

export default Header