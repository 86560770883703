import React, { Component } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { Link } from "gatsby"

import Layout from "../../components/Layout"
import Header from "../../components/Header/Header"
import Footer from "../../components/Footer"
import PageTitle from "../../components/PageTitle"

const StyledContainer = styled.div`
  margin-top: 50px;
`

const StyledContentContainer = styled.div`
  width: 100% !important;
`

const StyledList = styled.ul`
  list-style-position: inside;
`

export class smartFarming extends Component {
  render() {
    return (
      <div className="clearfix">
        <Layout />
        <Header />
        <div>
          <PageTitle title="M8 News" link="/news" />

          <StyledContainer className="container clearfix">
            <div className="single-post nobottommargin">
              {/* Entry Image*/}
              <div className="entry-image bottommargin">
                <Img
                  fluid={this.props.data.main.childImageSharp.fluid}
                  alt="Blog Single"
                />
              </div>
              {/* .entry-image end*/}
              {/* Post Content*/}
              <StyledContentContainer className="postcontent nobottommargin clearfix">
                {/* Single Post*/}
                <div className="entry clearfix">
                  {/* Entry Title*/}
                  <div className="entry-title">
                    <h2>Smart Farming</h2>
                  </div>
                  {/* .entry-title end*/}
                  {/* Entry Meta*/}
                  <ul className="entry-meta clearfix">
                    <li>
                      <i className="icon-calendar3" /> 30th July 2019
                    </li>
                  </ul>
                  {/* .entry-meta end*/}
                  {/* Entry Content*/}
                  <div className="entry-content notopmargin">
                    <p>
                      A couple hundred years ago 90% of the U.S. population
                      lived on farms and created their own food. Now, only 2% of
                      the population produces the{" "}
                      <a href="https://animalsmart.org/animals-and-the-environment/comparing-agriculture-of-the-past-with-today">
                        world's food supply
                      </a>
                      .
                    </p>
                    <p>
                      How does such a small part of the population produce all
                      of our food? Farmers use smart farming technologies to
                      produce more crops and livestock.
                    </p>
                    <h3>What is smart farming?</h3>
                    <p>
                      Smart Farming is a farm management concept using modern
                      technology to increase the quality and quantity of
                      agricultural products. Available modern information and
                      communication technologies for present day farmers include
                    </p>
                    <StyledList>
                      <li>
                        Data analytics solutions — helps with the decision
                        making process and forming predictions
                      </li>
                      <li>
                        Software applications, used to maintain day to day farm
                        operations
                      </li>
                      <li>
                        Hardware applications — automation and robotics that
                        connect with IoT Solutions
                      </li>
                      <li>
                        Sensing technologies, can check moisture levels of soil,
                        flow, pressure, light, temperature, humidity
                      </li>
                      <li>
                        Positioning technologies (GPS) - farmers can create maps
                        with precise acreage for their fields and map insect,
                        weed, and pest infestations
                      </li>
                    </StyledList>
                    <p />
                    <h3>IoT Farming</h3>
                    <p>
                      The “Internet of Things” (IoT) is becoming a huge topic of
                      interest among every industry. Simply, IoT is the concept
                      of taking any device with a power source and connecting it
                      to the internet.
                    </p>
                    <p>
                      With the expansion of IoT, connected devices and
                      technology has been integrated into every part of our
                      lives - home automation, health, industrial IoT, logistics
                      and automotive. It is only natural that IoT would make its
                      way to the agriculture industry. Within the industry, IoT
                      can help excel two specific areas within farming:
                      precision farming and farming automation.
                    </p>
                    <h3>Precision Farming</h3>
                    <p>
                      Precision Farming is the concept of using IoT solutions in
                      order to make farming operations more accurate and
                      efficient. Technology is used to monitor specific crops or
                      livestock and help collect data to assist the decision
                      making process. This technique of farming allows for crops
                      and livestock to receive specialized treatment.
                    </p>
                    <h3>Precision Livestock Farming</h3>
                    <p>
                      PLF uses integrated technologies to monitor individual
                      farm animals and collect data in real time. This includes
                      output of produce, animal wellness, animal behavior,
                      growth, feed conversion, and physical environment.
                    </p>
                    <p>
                      With this data, farmers can form educated decisions about
                      the needs of their animals and manage large herds more
                      efficiently. Efficient farming results in larger
                      production of animal products and high quality food.
                    </p>
                    <h3>The Green Revolution</h3>
                    <p>
                      The Third Green Revolution comes with the innovation of
                      robotics, IoT technologies, management software, automated
                      farm solutions, analytics, big data, and sensors.
                    </p>
                    <p>
                      Smart Farming technologies can facilitate an increase in
                      food production and food safety. These technologies can
                      also be used to benefit the environment. For instance, M8
                      Systems Waterguard manages water usage and stops leaks,
                      which contributes to water conservation.
                    </p>
                    <p>
                      In this revolution, all farm operations will be optimized
                      for production making farming easier and more efficient.
                      In the near future, smart farms will sustain the food
                      supply of our planets growing population.
                    </p>
                    <div className="clear" />
                    {/* Post Single - Share*/}
                    <div className="si-share noborder clearfix">
                      <span>Share this Post:</span>
                      <div>
                        <a
                          className="social-icon si-borderless si-facebook"
                          href="https://www.facebook.com/M8Systems/"
                        >
                          <i className="icon-facebook" />
                          <i className="icon-facebook" />
                        </a>
                        <a
                          className="social-icon si-borderless si-linkedin"
                          href="https://www.linkedin.com/company/m8systems/about/"
                        >
                          <i className="icon-linkedin" />
                          <i className="icon-linkedin" />
                        </a>
                      </div>
                    </div>
                    {/* Post Single - Share End*/}
                    {/* .entry end*/}
                    <div className="line" />
                    {/* Post Author Info*/}
                    <div className="card">
                      <div className="card-header">
                        <strong>
                          Posted by {/* */}
                          <Link to="/leadership">Isabelle Safai</Link>
                        </strong>
                      </div>
                      <div className="card-body">
                        <div className="author-image">
                          <Img
                            fluid={
                              this.props.data.isabelle_safai.childImageSharp
                                .fluid
                            }
                            placeholderClassName="rounded-circle"
                            alt="Isabelle Safai"
                          />
                        </div>
                        Isabelle Safai is the digital marketing manager at M8
                        Systems. She specializes in content creation and search
                        engine optimization. Isabelle is pursuing degrees in
                        computer science and management information systems. She
                        is an avid hiker and has a fascination with nature. Her
                        goal is to help create and promote technology designed
                        for helping the environment.
                      </div>
                    </div>
                  </div>
                </div>
              </StyledContentContainer>
            </div>
          </StyledContainer>
        </div>
        <Footer />
      </div>
    )
  }
}

export const query = graphql`
  query {
    main: file(relativePath: { eq: "blog/smart-farming.jpeg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    isabelle_safai: file(relativePath: { eq: "team/isabelle-safai.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default smartFarming
