import React, { Component } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const StyledContainer = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #eee;

  h1 {
    font-size: 28px;
    color: #333;
    font-weight: 600;
    line-height: 1;
    margin: 0px;
    padding: 0px;
    text-transform: uppercase;
  }

  @media (max-width: 768px) {
    padding-top: 20px;
    padding-bottom: 20px;

    h1 {
      line-height: 1.5;
      font-size: 24px;
    }
  }
`

export class PageTitle extends Component {
  render() {
    return (
      <StyledContainer className="container-fluid clearfix">
        <div className="container">
          <Link to={this.props.link}>
            <h1>{this.props.title}</h1>
          </Link>
        </div>
      </StyledContainer>
    )
  }
}

export default PageTitle
